
.question {
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
}
.longQuestion {
    font-size: 15px;
    margin-bottom: 15px;
    text-align: center;
}
.answer {
    margin-bottom: 5px;
    width: 100%;
}
.required {
    color: red;
    font-size: 12px;
}
.nextBtn {
    margin-top: 15px;
    background: #ffea75;
    border: none;
    border-radius: 3px;
    height: 43px;
    line-height: 43px;
    white-space: nowrap;
    padding: 0 85px;
    outline: none;
    user-select: none;
}

.nextBtn:hover {
    box-shadow: 0px 0px 10px 1px #fbdb2b;
    background-color: #fbdb2b;
}

.greenBtn {
    background: #1bb551;
    color: white;
}
.greenBtn:hover {
    box-shadow: 0px 0px 10px 1px #169c45;
    background-color: #169c45;
}
.textarea {
    height: 75px;
    width: 100%;
    padding: 5px;
    -webkit-appearance: none;
    border: 1px solid grey;
    border-radius: 3px;
}
.input {
    width: 100%;
    padding: 5px;
    -webkit-appearance: none;
    border: 1px solid grey;
    border-radius: 3px;
    height: 43px;
    line-height: 43px;
}

.personalData {
    margin-top: 10px;
    width: 100%;
    font-size: 12px;
    display: flex;
    align-items: center;
}

.note {
    font-size: 16px;
    color: #b9b9b9;
    margin-top: 20px;
}

.radioGroup {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: none;
    padding: 0;
    justify-content: center;
}
.circle {
    background: #d4d4d4;
    width: 40px;
    height: 40px;
    color: black;
    border-radius: 50%;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px;
    cursor: pointer;
    user-select: none;
}
.radio {
    display: none;
}

.radio:checked ~ .circle {
    color: white;
    font-weight: bold;
}
.radio:checked ~ .circle[data-color=red] {
    background: #bf4b4b;
    box-shadow: 0px 0px 10px 1px #bf4b4b;
}

.radio:checked ~ .circle[data-color=orange] {
    background: #ffb400;
    box-shadow: 0px 0px 10px 1px #ffb400;
}
.radio:checked ~ .circle[data-color=green] {
    background: #76bf4b;
    box-shadow: 0px 0px 10px 1px #76bf4b;
}
