html, body, #root, main {
  height: 100%;
}
body {
  font-family: Arial, Helvetica, sans-serif;
}
textarea, input[type=text], input[type=tel] {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

main {
  display: flex;
  flex-direction: column;
}
.MuiSvgIcon-root {
  width: 50px !important;
  height: 50px !important;
}
.MuiRating-root {
  flex-wrap: wrap;
  justify-content: center;
}
.MuiRating-icon {
  position: relative;
}
* {
  
  box-sizing: border-box;
}/*
[class*="bonuses_wrapper"] + [class*="thank-you_wrapper"] {
  padding-top: 100px;
}
[class*="bonuses_wrapper"] + [class*="pages_wrapper"] {
  padding-top: 80px;
}*/