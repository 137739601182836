.wrapper {
    display: flex;
    flex-direction: column;
    max-width: 350px;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    flex-grow: 1;
}
.heading {
    font-size: 28px;
    font-weight: normal;
}

.text {
    font-size: 18px;
}

.logo {
    width: 90px;
}