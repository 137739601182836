.wrapper {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    flex-grow: 1;
}
.logo {
    width: 100%;
    margin-bottom: 20px;
    max-width: 50%;
}
.upload {
    width: 100%;
    margin-bottom: 50px;
    max-width: 30%;
}

.note {
    font-size: 16px;
    color: #b9b9b9;
}