
.link {
    background: #ffea75;
    border: none;
    margin-bottom: 20px;
    cursor: pointer;
    outline: none;
    
    border-radius: 10px;
    height: 70px;
    padding: 10px;

    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}
.link:hover {
    box-shadow: 0px 0px 10px 1px #fbdb2b;
    background-color: #fbdb2b;
}
.linkArrow:after {
    content: '';
    display: block;
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 10px;
    transform: rotate(-45deg) translate(-3px, -3px);
}
.linkImg {
    height: 60%;
}
.linkArrow {
    height: 50px;
    width: 50px;
    background: white;
    border-radius: 50%;    
    display: flex;
    align-items: center;
    justify-content: center;
}
.wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 100%;
}